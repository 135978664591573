.Home {
  background-color: #0c1821;
  color: white;
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  color: white;
  font-size: 60px;
  font-weight: bold;
  margin: 0;
  text-shadow: 0 0 1px #ccc9dc;
}

.subtitle {
  text-align: center;
  margin-top: 20px;
  font-size: 30px;
  text-shadow: 0 0 1px #ccc9dc;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.social-icon {
  text-align: center;
  margin: 0 10px;
}

.logo{
  height: 80px !important;
  width: 80px !important;
}

.social-icon a {
  text-decoration: none;
  color: white;
  display: block;
  font-size: 24px;
  margin-top: 10px;
}

.scroll-down {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 30px;
  animation: scrollDown 1s infinite;
}

@keyframes scrollDown {
  0%, 100% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(10px);
  }
}

body {
  margin: 0;
  overflow: hidden;
}
