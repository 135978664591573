.container{
  background-color: #0c1821;
  color: white;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
}

.items{
  height: auto;
}

@media (min-width: 768px){
  .container{
    height: 100vh;
  }
  .items{
    display: flex;
    align-items: center;
  }
}

.card{
  display: flex;
  justify-content: center;
  transition: transform 0.5s ease !important;
}

.card:hover{
  transform: scale(1.03);
}

body{
  overflow: scroll;
  -ms-overflow-style: none;
  background-color: #0c1821;
}

.grid{
  display: flex;
  justify-content: center;
}

.card-inside{
  border-radius: 15px !important;
  box-shadow: 0 0 5px #ccc9dc !important;
}

.cardContent{
  position: absolute;
  top: 130px;
  width: 313px;
  z-index: 1;
  height: 90px;
  background-color: #324a5f !important;
  border-radius: 3px !important;
  color: white !important;
}

.cardImage{
  background-color: white !important;
  position: relative;
  z-index: 0;
  height: 150px;
  overflow: visible;
}


.errorNotification-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.errorNotification {
  background-color: #ff3b47;
  color: white;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
}

.projectsTitle{
    font-size: 40px;
    font-weight: bold;
    text-shadow: 0 0 1px #ccc9dc;
}