.aboutMe {
  background-color: #0c1821;
  color: white;
  text-align: left;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fakeButtons {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  border: 1px solid #9d252b;
  position: relative;
  top: 6px;
  left: 6px;
  background-color: #ff3b47;
  display: inline-block;
}

.fakeMinimize {
  left: 11px;
  background-color: #ffc100;
  border-color: #9d802c;
}

.fakeZoom {
  left: 16px;
  background-color: #00d742;
  border-color: #049931;
}

.fakeMenu {
  width: 800px;
  box-sizing: border-box;
  height: 25px;
  background-color: #bbb;
  margin: 0 auto;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.fakeScreen {
  background-color: #060c11;
  box-sizing: border-box;
  width: 800px;
  height: 500px;
  margin: 0 auto;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

p {
  position: relative;
  text-align: left;
  font-size: 1.25em;
  font-family: monospace;
  white-space: normal;
}

a {
  color: #9cd9f0;
  text-decoration: none;
  cursor: pointer;
}

span {
  color: #fff;
  font-weight: bold;
}

.command-line {
  color: #9cd9f0;
}

.response-line {
  color: #bcb9b9;
  /*white-space: pre-wrap;*/
}

.cursor-line {
  color: #fff;
}

.cursor4 {
  -webkit-animation: blink 1s 0s infinite;
  -moz-animation: blink 1s 0s infinite;
  -o-animation: blink 1s 0s infinite;
  animation: blink 1s 0s infinite;
}

@-webkit-keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-moz-keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@-o-keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .fakeMenu {
    width: 95%;
  }

  .fakeScreen {
    width: 95%;
    padding: 10px;
  }
}

@media only screen and (max-height: 790px) {
  .fakeScreen {
    height: 400px;
  }
}
